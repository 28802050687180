import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Experience from 'components/Experience';
import Education from 'components/Education';
import Skills from 'components/Skills';

const ResumePage: React.FC = () => (
  <Layout>
    <SEO title="Resume" />
    <Experience />
    {/* <hr />
    <Education /> */}
    <hr />
    {
      // <Skills />
    }
    {
      xxx()
    }

  </Layout>
);

const xxx =()=>{
  return(
    <div style={{
      width:'100%',
    display:'flex',justifyContent:'center',alignItems:'center',
    }}>

    <div style={{
    minWidth:390,
    maxWidth:790,
    marginTop:40,
    paddingLeft:30,paddingRight:30,
    display:'flex',justifyContent:'flex-start',alignItems:'flex-start',
    textAlign:'left',flexDirection:'column',
    // fontSize:16,
    }}>
    <p style={{fontSize:18,fontWeight:'bold'}}>我們的服務</p>
    <p></p>
    <p>我們擁有多年電子產品開發經驗，專注於為客人提供完整電子、金屬和塑料生產定制製造服務，由工業級至消費級產品。</p>
    <p>服務流程由您的初始意念，經由我們的專業團隊提供創意及技術設計，以至原形製作直到最終產品的製造。服務亦包括原材料及零件採購，製造工藝的選擇。定能為您的產品提供一站式製造服務。</p>
    <p></p>
    </div>

    </div>
  )
}

export default ResumePage;
