import React,{useState} from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import InfoBlock from 'components/ui/InfoBlock';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import * as Styled from './styles';

import ContactForm from './form'
const ConctactInfo = () => {
// class ConctactInfo extends React.Component {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "contact section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "contact" } } }, sort: { fields: fileAbsolutePath }) {
        edges {
          node {
            id
            frontmatter {
              title
              icon
              content
              hidden
            }
          }
        }
      }
    }
  `);

  const sectionTitle = markdownRemark.frontmatter;
  const contacts = allMarkdownRemark.edges;

  // form values
  // const [name,setName] = useState('');
  // const [email,setEmail] = useState('');
  // const [tel,setTel] = useState('');
  // const [content,setContent] = useState('');

  return (
    <Container section>
      <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} center />

      <ContactForm/>


      <div style={Styles.rowContainer}>
      {contacts.map((item) => {
        const {
          id,
          frontmatter: { title, icon, content,hidden }
        } = item.node;

        if(hidden=='1'){
          return null;
        }
        return (
          <Styled.ContactInfoItem key={id}>
            <InfoBlock icon={icon} title={title} content={content} center />
          </Styled.ContactInfoItem>
        );
      })}
      </div>

    </Container>
  );
};


const Styles = {

  rowContainer:{
    width:'100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap:'wrap',
    // border: '1px solid red',
    // alignItems:'center',
    // justifyContent:'center',
    marginTop:10,
  },
}

export default ConctactInfo;
