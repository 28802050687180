import React from 'react';

import API from '../API/API'

import {
  loadReCaptcha,
  ReCaptcha
} from 'react-recaptcha-v3'


const ReCaptchaPublicKey='6LdMs8UaAAAAABvT2hptnaUtwOooboeJW52SAwnw'
const API_URL = "https://4km-mailer.epic-comm.net/public/api/sendmail";
const API_KEY = 'xcKY5DZ686DnvDRaHHuH8tC7ZttfJ9U6XwFDKwkHsNFy48jBFpaqFp7hFapZaUVXfdcBknUC2rLUpFmFT7FbkDjBDhsq3YXT3Zkcd5rQ6MAkhxBNv7PrAJSQRUQKJEun'

// import { useStateWithCallbackLazy } from 'use-state-with-callback';
// /*
class ContactForm extends React.Component {
  // form values

  componentDidMount() {
    loadReCaptcha(ReCaptchaPublicKey, this.loadReCaptchaCallback);

    // const script = document.createElement("script");
    // script.src = "https://use.typekit.net/foobar.js";
    // script.async = true;
    // document.body.appendChild(script);
  }

  loadReCaptchaCallback=(obj)=>{
      // console.log('loadReCaptchaCallback:',obj)
  }

  constructor(props) {
	   super(props);
     this.state = {
       name:'',
       email:'',
       tel:'',
       content:'',

       // name:'almorak',
       // email:'almorak@epic-comm.com',
       // tel:'1231231231',
       // content:'12312131133',

       inputCheckList:[
          {key:'name',emptyCheckPrefix:'稱謂'},
          {key:'email',emptyCheckPrefix:'電郵'},
          {key:'tel',emptyCheckPrefix:'電話'},
          {key:'content',emptyCheckPrefix:'查詢內容',minLength:10},
       ],
       invalidMsg:{
          name:'',
          email:'',
          tel:'',
          content:'',
       },

       currentReCaptchaToken:'',
       ReCaptchaSuccess:false,
     }
     this.onChange = this.onChange.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
   }

   onChange=(e)=>{
     this.setState({[e.target.name]: e.target.value})
   }

   handleSubmit=(e)=>{
    e.preventDefault();
    // console.log('HandleSubmit:',e)
    let invalidObjs = {
      hasDoCheck:true,
      invalidMsg:{
        name:'',
        email:'',
        tel:'',
        content:'',
      },
    }
    let isAllVaild = true

    let checkList = this.state.inputCheckList;

    for (var i = 0; i < checkList.length; i++) {
      if( !this.state[checkList[i].key]){ // empty
        invalidObjs.invalidMsg[ checkList[i].key] = checkList[i].emptyCheckPrefix+'不能空白'
        isAllVaild = false;
      }
      else if (checkList[i].minLength && (this.state[checkList[i].key].length < checkList[i].minLength)) {
        invalidObjs.invalidMsg[ checkList[i].key] = '最少 '+checkList[i].minLength+' 個字'
        isAllVaild = false;
      }
      else {
        invalidObjs.invalidMsg[ checkList[i].key] = ''
      }
    }

    this.setState(invalidObjs)
    if(isAllVaild == false){
      return
    }

    this.setState({
      proccessGetRecaptchaToken:true,

    },()=>{
      this.updateToken();
    })
  }

  callApiSendmail=async()=>{
    // console.log(this.state.apiProccessing)
    if(this.state.apiProccessing == true){
      return;
    }

    let data = JSON.stringify({
      mail_key:API_KEY,
      name: this.state.name,
      email_to: this.state.email,
      tel: this.state.tel,
      content: this.state.content,
      recaptcha_token:this.state.currentReCaptchaToken,
    })

    // console.log('data:',data)
    this.setState({
      apiProccessing:true,
    },async()=>{
      let res = await API.postData(API_URL,data)
      // console.log('res:',res)
      this.setState({
        apiProccessing:false
      },async()=>{
        if(res.status && res.status == 'Y'){
          // this.sendAdsConversion()
          alert(res.msg)
          window.location.reload();
        }else if(res.error) {
          alert(res.error)
        }else {
          alert('無法提交，請稍後再試')
          // window.location.reload();
        }
      })
    })
  }

  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    // console.log("your recaptcha token => ",recaptchaToken)
    this.state.currentReCaptchaToken = recaptchaToken;
    if(this.state.proccessGetRecaptchaToken == true){
      this.state.proccessGetRecaptchaToken = false
      this.callApiSendmail()

    }
  }

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }

  render() {
    return(
      <form style={Styles.containerFullWidth} onSubmit={this.handleSubmit}>
        {
        // <label style={Styles.title}> 立即查詢 </label>  
        }

        <input style={Styles.input} type="text" name="name" placeholder="稱謂"
        value={this.state.name} onChange={this.onChange} />
        <label style={Styles.invalidLabel}> {this.state.invalidMsg.name} </label>

        <input style={Styles.input} type="email" name="email" placeholder="電郵"
        value={this.state.email} onChange={this.onChange} />
        <label style={Styles.invalidLabel}>{this.state.invalidMsg.email}</label>


        <input style={Styles.input} type="text" name="tel" placeholder="電話"
        value={this.state.tel} onChange={this.onChange} />
        <label style={Styles.invalidLabel}>{this.state.invalidMsg.tel}</label>


        <textarea style={Styles.inputArea}  name="content" placeholder="查詢內容"
        value={this.state.content} onChange={this.onChange} />
        <label style={Styles.invalidLabel}>{this.state.invalidMsg.content}</label>

        <input style={Styles.submitBtn} type="submit"
        value={this.state.apiProccessing?"提交中" : "提交" }/>

        {
          <ReCaptcha
              ref={ref => this.recaptcha = ref}
              // sitekey="<strong>6Ld5kLsaAAAAACanf11kUT4fmUEZvmb1tsiTqbpX</strong>"
              sitekey={ReCaptchaPublicKey}
              action='action_name'
              verifyCallback={(obj)=>{this.verifyCallback(obj)}}
          />
        }

      </form>
    )
  }
}


const Styles = {
  container:{
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid blue',
    paddingLeft:40,
    paddingRight:40,
  },
  containerFullWidth:{
    // border: '1px solid blue',
    width:'100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center',
    marginBottom:30,
  },
  columnContainer:{
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid blue',
    // maxWidth:300,
    // width:400,
  },
  rowContainer:{
    display: 'flex',
    flexDirection: 'row',
    flexWrap:'wrap',
    // border: '1px solid red',
    // alignItems:'center',
    // justifyContent:'center',
    marginTop:10,
  },
  title:{
    // color:'#ed64a6',
    color:'#3C356B',
    fontSize:24,
  },
  invalidLabel:{
    height:20,
    fontSize:12,
    color:'#ff0000',
    textAlign:'right',
    // marginBottom:12,
    paddingTop:2,
    // paddingLeft:0,
    width:300,
  },
  input:{
    color:'#3C356B',
    borderWidth:1,
    // borderColor:'#999999',
    marginTop:10,
    borderRadius:4,
    padding:'8px 10px',
    height:36,
    placeholder:'#ffffff',
    // backgroundColor:'#00000044',
    fontSize:14,
    // maxWidth:300,
    width:300,
  },
  validBorder:{
    borderColor:'#ffffff',
  },
  invalidBorder:{
    borderColor:'#ff0000',
  },
  inputArea:{
    color:'#3C356B',
    // borderColor:'#999999',
    borderRadius:4,
    padding:'8px 10px',
    borderWidth:1,
    marginTop:10,
    height:76,
    placeholder:'#ffffff',
    width:300,
    fontSize:14,
    overflow:'hidden',
    resize:'none',
  },
  inputCheckBoxDiv:{
    display: 'flex',
    minWdith:16,
    // minHeight:16,
  },
  inputCheckBox:{
    margin:0,
    width:16,
    height:16,
    // borderRadius:4,
    // display:'block',
    // backgroundColor:'#00000044',
    // borderWidth:0,
  },
  checkboxLabel:{
    // margin:0,
     // minWdith:200,
    marginLeft:10,
    fontSize:14,
    color:'#ffffff',
    textAlign:'left',
    borderWidth:1,
    borderColor:'#ffffff',
    fontWeight:'400',
    lineHeight:1,
  },
  submitBtn:{
    marginTop:30,
    width:300,
    height:40,
    borderRadius:4,
  },

}
// */

export default ContactForm;
