'use-strict'



const timeoutSeconds = 20;

export  default class API {


static async postData(url, param,contentType='json') {
  ////  console.log(param);
   let p1 = await new Promise((resolve, reject) => {
     let tempContentType = 'application/json'
     if(contentType = 'json'){
       tempContentType = 'application/json'
     }else if(contentType = 'formdata') {
       tempContentType = 'multipart/form-data'
     }
     fetch(( url), {
       method: 'POST',
       headers: {
         'Accept': 'application/json',
         'Content-Type': tempContentType,
       },
       body: param,
     })
     .then((response) =>
      {
        var result=''
       console.log('postData:',response)
       try {
          result = response.json()
          // result = JSON.parse(response)
          // result = JSON.parse( response.body)
       } catch (e) {
         result ={error:e}
       }
        // console.log('result:',result)
       return result
      }
     )
     .then((responseJson) => {

      //  if (responseJson.status === 'Y')
      //  {
         resolve(responseJson);
      //  }
      //  else
      //  {
      //    reject(responseJson.message);
      //   //  Alert.alert(responseJson.message, responseJson.alert);
      //   // console.log('postData E :',responseJson.message,responseJson.alert);
      //  }
     })
     .catch((error) => {
       // reject(error);
       resolve(error)
     })
   });

   let p2 = this.requestTimeout();

   return Promise.race([p1,p2]);
 }

 //Get Request
 static getData(url) {
   // let p1 = new Promise((resolve, rejcet) => {
   //   fetch( url,{
   //     method: "GET",
   //     headers: {
   //           'Content-Type': 'application/json'
   //         },
   //
   //   })
   //   .then((response) => response.json())
   //   .then((responseJson) => {
   //     resolve(responseJson);
   //   })
   //   .catch((error) => {
   //     reject(error);
   //   })
   // });
   //
   // let p2 = this.requestTimeout();
   //
   // return Promise.race([p1,p2]);

   let p1 = new Promise((resolve, rejcet) => {
     fetch( url)
     .then((response) => response.json())
     .then((responseJson) => {
       resolve(responseJson);
     })
     .catch((error) => {
       // reject(error);
       return error
     })
   });

   let p2 = this.requestTimeout();

   return Promise.race([p1,p2]);
 }

 //Timeout Request
 static requestTimeout() {
   return new Promise((resolve, reject) => {
     setTimeout(() => {
       // reject('Request Timeout');
       return 'Request Timeout'
     }, timeoutSeconds * 1000);
   });
 }





}
