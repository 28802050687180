import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Banner = styled.section`
  ${tw`bg-gray-100 border-b border-indigo-100 `};
`;

export const Content = styled.div`
  ${tw`mb-12 w-full`};
`;
